import React from 'react';
import './Head.less';

import Logo from '@component/Logo/Logo';
import Nav from '@component/Nav/Nav';
import Slogan from '@component/Slogan/Slogan';

interface Inav {
  name: string;
  path: string;
}

interface Istate {
  navlist: Inav[];
  [stateName: string]: any;
}

interface Iprops {
  [propName: string]: any;
}

class Head extends React.Component<Iprops, Istate>{
  constructor(props: any) {
    super(props);
    this.state = {
      navlist: [
        { name: '文章', path: 'article' },
        { name: '留言', path: 'message' },
        { name: '相册', path: 'album' },
        { name: '关于', path: 'about' }
      ]
    }
  }

  render(): JSX.Element {
    return (
      <section className="header">
        <section className="logo">
          <Logo/>
        </section>
        <section className="nav">
          <Nav navlist={this.state.navlist}/>
        </section>
        <section className="slogan">
          <Slogan/>
        </section>
      </section>
    )
  }
}

export default Head;