import React from 'react';
import './Nav.less';
import { Menu } from 'antd';

interface Istate {
  [propName: string]: any;
}

interface Iprops {
  [propName: string]: any;
}


export default class Nav extends React.Component<Iprops, Istate> {
  constructor(props: Iprops) {
    super(props);
    this.state = {
      current: 'article',
      switchPath: ''
    }
  }

  handleClick = (e: any) => {
    // let switchPath: string = `#/${e.key === 'article' ? '' : e.key}`;
    let switchPath: string = `#/${e.key}`;
    this.setState({
      current: e.key,
      switchPath
    })
  }

  render(): JSX.Element {
    return (
      <section className="navbar">
        <Menu onClick={this.handleClick} selectedKeys={[this.state.current]} mode="horizontal">
          {this.props.navlist.map((item: Iprops) => {
            return (
              <Menu.Item key={item.path}>
                <a href={this.state.switchPath}>{item.name}</a>
              </Menu.Item>
            )
          })}
        </Menu>
      </section>
    );
  }
}

