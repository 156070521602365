import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IpropAny } from '@/interface/propsInterface';
import Img from '@component/Img/Img';
import { RightOutlined } from '@ant-design/icons';
import { Iarticle } from '@/interface/articleInterface';
import { updateArticleContent } from '@/action/act_article';
import './List.less';

class List extends React.Component<IpropAny> {

  test = (article: any) => {
    console.log(article);
  }

  render(): JSX.Element {

    const updateArticle = (article: Iarticle) => {
      console.info(article);
      this.props.updateArticleContent(article);
    }

    return (
      <section className="article-list">
        {this.props.articleList.map((article: Iarticle, index: number) => {
          return (
            <section key={index} className="list-item">
              <section className="article-img">
                <Img imgurl={article.imgPath} />
              </section>
              <section className="article-section">
                <h3>
                  <Link to="/article/content">{article.title}</Link>
                </h3>
                <section className="article-info" onClick={this.test.bind(this, article)}>
                  <span>作者：{article.author}</span>
                  <span>发表时间：{article.createTime}</span>
                </section>
                <section className="context">{article.context}</section>
                <section className="read-more" onClick={updateArticle.bind(this, article)}>
                  <Link to="/article/content">
                    <span>详情</span><RightOutlined />
                  </Link>
                </section>
              </section>
            </section>
          )
        })}
      </section>
    )
  }
}

const mapStateToProps = (state: any) => ({

})

const mapDispatchToProps = (dispatch: any) => ({
  updateArticleContent: (article: Iarticle) => {
    dispatch(updateArticleContent(article));
  }
})


export default connect(mapStateToProps, mapDispatchToProps)(List);