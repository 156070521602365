import { CUEEENT_ARTICLE_CONTENT } from '@/constant/const_acticle';
import { Iaction } from '@/interface/actionInterface';

let initArticleContent = {
  articleItem: {}
}

export function updateArticleContent(state = initArticleContent, action: Iaction) {
  switch(action.type) {
    case CUEEENT_ARTICLE_CONTENT: 
    return {
      articleItem: action.value
    }
    default: 
    return state;
  }
}