import React from 'react';
import { Carousel } from 'antd';
import './Swiper.less';
import { IpropNumber } from '@/interface/propsInterface';
import { IstateNumber } from '@/interface/stateInterface';

class Swiper extends React.Component<IpropNumber, IstateNumber> {
	constructor(props: any) {
		super(props);
		this.state = {
      autoplaySpeed: this.props.autoplaySpeed || 4000,
			speed: this.props.speed || 800
		}
	}
	render() {
		return (
			<section>
        <Carousel effect="fade" autoplay autoplaySpeed={this.state.autoplaySpeed} speed={this.state.speed}>
					<div>
						<h3>生命有限，只能接受</h3>
					</div>
					<div>
            <h3>一身清贫怎敢入繁华，两袖清风怎敢误佳人</h3>
					</div>
					<div>
            <h3>谁不愿一生顺顺当当，不写悲伤；谁不愿感情不离不弃，一生珍藏</h3>
					</div>
					<div>
            <h3>心有所累，尽力而为</h3>
					</div>
				</Carousel>
			</section>
		)
	}
}

export default Swiper;