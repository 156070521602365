import React from 'react';
import { IpropAny } from '@/interface/propsInterface';
import List from '@component/List/List';

interface Iarticle{
  title: string;
  context: string;
  [stateName: string]: string;
}

interface Istate {
  articleList: Iarticle[]
}

class Lifestroy extends React.Component<IpropAny, Istate> {
  constructor(props: IpropAny) {
    super(props);
    this.state = {
      articleList: [
        { 
          title: '生活永远在爱情之上', 
          context: '女孩跟男友相恋了两年，男友对她好，挣的钱任她花，她对男友也好，为他洗洗涮涮，缝缝补补，两人的小日子过得有滋有味。可天有不测，男友突然遭遇车祸，受了重伤，完全丧失了劳动能力。只有二十四岁，余生都需要人照顾了。', 
          author: 'jilon', 
          createTime: '2020-04-11',
          imgPath: '1.jpg'
        },
        { 
          title: '种花草,养生活', 
          context: `小时候，我家住在小城西南的一片平房里。那时的生活、物质条件远不如今，但爱美的人家都会在自家窗台下围起一道道的篱笆，并且撒下一些比较容易成活的花种。春天到来时，阳光斜照在窗前的园子里，光灿灿的黄撒得满地、满屋都是，如同秋收后颗粒饱满的橙黄色稻穗，
          让人看了忍不住把笑容挂在眼角、挂在眉梢。花开后，引来无数蝶舞蜂飞，任淡淡花香轻飘在如水似梦的季节里。种花草, 养生活竹篱中，修长的美人蕉花蔓低垂，似羞，又似在喃喃细语。一大片深绿、一大片浅绿的轻纱罗裙，层层叠叠褶皱着包裹了它娇美、玲珑的身体，以至于漂亮的瓢虫姑娘不小心跌落在它的裙褶中，
          只好在翠意浓浓的茎脉上奋力地爬行。看到这小小的瓢虫，
          谁都不会怀疑它是把春天送到我们面前的信使。瓢虫挣脱了绿叶的拥抱，展开薄的、透明的翅膀，跟随着漫天飞舞的蒲公英的小花伞，一上一下嬉闹着远去了。
          园子里能跟美人蕉争艳的当数大丽花了。大丽花的花瓣像堆积在一起的毛茸茸的猫耳朵，竖立在媚态十足的阳光里，似乎是在探听莺喃雁语里的爱隋。深红的、粉白的、大红的，每一朵笑脸上都看到了春姑娘的身影。
          站在竹篱外，闪亮的眸子紧盯着盛开的满园花草，生怕一眨眼的工夫它们就会消失得无踪无迹。如果真是那样，我还能到哪里再寻得这满眼的芬芳、满心的春情呢？
          大丽花的花根处，家人还种了些没有见过花开的薄荷。也许只有在夜深人静的时候，薄荷花才会羞怯怯地开放在无人的月夜里。揉碎后的薄荷叶子上散发出阵阵薄荷糖样的、清凉的香味。有时，我也会采下一些薄荷叶让父亲拿去用鸡蛋炒炒，那种感觉就像在吃梦寐已久的大餐。当飘着清香的薄荷叶炒蛋放在我的小碗里时，爸爸就会说：“天热了，吃点薄荷能解热去毒。”
          我不善养花草，虽说喜爱之情并不亚于他人，可总怕在我的伺弄下花草会渐渐枯萎，变成没有生命、没有水份的“干尸”。于是，不管遇见多艳丽、多优雅、多清香的花草，我也只是驻足下来一盆盆地欣赏。这时，卖花人会极力推荐，他的花木不仅品种好，而且成活率高。我用心地听着，然后老实作答：“看看就行，买回去怕养不活呢！”
          前几日，先生从国外回来，说想要让自家的小院变成长着绿草坡的花园。
          我笑笑说，只要你有时间，就来开辟吧！
          但先生太忙了，他每次也只是说说而已。不知道家中这小小的庭院里，何时才能芳香盈盈呢！
          数日前，院外又一次遇到那个卖花人。迎上去仔细打量着花的品种，花农说：“来一盆吧！多美丽的花，有了它们，你会天天陶醉在春天里。”
          哦，我想花农说得有道理。有了好的心情，有了一份花开的希望，我们才能生活得自如，不是吗？正如小时候，窗下竹篱里曾经承载着多少对美好生活的渴望，这种渴望在园子里的花朵上，更在爱花人的心里。于是，这次我买下了两盆比较容易管理的马蹄莲和茉莉花，同时也把一份殷殷的祝愿搬回了家。
          花盆搁置在台阶上：我们相互对望着，我赏识它的自然、青翠，还有昂扬的生命力。把儿子叫过来，俯下身一起闻着茉莉浓浓的花香，好让他在以后的岁月里也能记得，记得我不曾忘却的竹篱花香。
          现在想来，生活就如同养花。只要用一份努力、一份关爱、一种深情去培养、呵护，美好就会开放在我们目所能及的任何角落里。`,
          author: 'jilon', 
          createTime: '2020-04-11',
          imgPath: '2.jpg'
        },
        {
          title: '生活永远在爱情之上',
          context: '女孩跟男友相恋了两年，男友对她好，挣的钱任她花，她对男友也好，为他洗洗涮涮，缝缝补补，两人的小日子过得有滋有味。可天有不测，男友突然遭遇车祸，受了重伤，完全丧失了劳动能力。只有二十四岁，余生都需要人照顾了。',
          author: 'jilon',
          createTime: '2020-04-11',
          imgPath: '3.jpg'
        },
        {
          title: '生活永远在爱情之上',
          context: `小时候，我家住在小城西南的一片平房里。那时的生活、物质条件远不如今，但爱美的人家都会在自家窗台下围起一道道的篱笆，并且撒下一些比较容易成活的花种。春天到来时，阳光斜照在窗前的园子里，光灿灿的黄撒得满地、满屋都是，如同秋收后颗粒饱满的橙黄色稻穗，
          让人看了忍不住把笑容挂在眼角、挂在眉梢。花开后，引来无数蝶舞蜂飞，任淡淡花香轻飘在如水似梦的季节里。种花草, 养生活竹篱中，修长的美人蕉花蔓低垂，似羞，又似在喃喃细语。一大片深绿、一大片浅绿的轻纱罗裙，层层叠叠褶皱着包裹了它娇美、玲珑的身体，以至于漂亮的瓢虫姑娘不小心跌落在它的裙褶中，
          只好在翠意浓浓的茎脉上奋力地爬行。看到这小小的瓢虫，
          谁都不会怀疑它是把春天送到我们面前的信使。瓢虫挣脱了绿叶的拥抱，展开薄的、透明的翅膀，跟随着漫天飞舞的蒲公英的小花伞，一上一下嬉闹着远去了。
          园子里能跟美人蕉争艳的当数大丽花了。大丽花的花瓣像堆积在一起的毛茸茸的猫耳朵，竖立在媚态十足的阳光里，似乎是在探听莺喃雁语里的爱隋。深红的、粉白的、大红的，每一朵笑脸上都看到了春姑娘的身影。
          站在竹篱外，闪亮的眸子紧盯着盛开的满园花草，生怕一眨眼的工夫它们就会消失得无踪无迹。如果真是那样，我还能到哪里再寻得这满眼的芬芳、满心的春情呢？
          大丽花的花根处，家人还种了些没有见过花开的薄荷。也许只有在夜深人静的时候，薄荷花才会羞怯怯地开放在无人的月夜里。揉碎后的薄荷叶子上散发出阵阵薄荷糖样的、清凉的香味。有时，我也会采下一些薄荷叶让父亲拿去用鸡蛋炒炒，那种感觉就像在吃梦寐已久的大餐。当飘着清香的薄荷叶炒蛋放在我的小碗里时，爸爸就会说：“天热了，吃点薄荷能解热去毒。”
          我不善养花草，虽说喜爱之情并不亚于他人，可总怕在我的伺弄下花草会渐渐枯萎，变成没有生命、没有水份的“干尸”。于是，不管遇见多艳丽、多优雅、多清香的花草，我也只是驻足下来一盆盆地欣赏。这时，卖花人会极力推荐，他的花木不仅品种好，而且成活率高。我用心地听着，然后老实作答：“看看就行，买回去怕养不活呢！”
          前几日，先生从国外回来，说想要让自家的小院变成长着绿草坡的花园。
          我笑笑说，只要你有时间，就来开辟吧！
          但先生太忙了，他每次也只是说说而已。不知道家中这小小的庭院里，何时才能芳香盈盈呢！
          数日前，院外又一次遇到那个卖花人。迎上去仔细打量着花的品种，花农说：“来一盆吧！多美丽的花，有了它们，你会天天陶醉在春天里。”
          哦，我想花农说得有道理。有了好的心情，有了一份花开的希望，我们才能生活得自如，不是吗？正如小时候，窗下竹篱里曾经承载着多少对美好生活的渴望，这种渴望在园子里的花朵上，更在爱花人的心里。于是，这次我买下了两盆比较容易管理的马蹄莲和茉莉花，同时也把一份殷殷的祝愿搬回了家。
          花盆搁置在台阶上：我们相互对望着，我赏识它的自然、青翠，还有昂扬的生命力。把儿子叫过来，俯下身一起闻着茉莉浓浓的花香，好让他在以后的岁月里也能记得，记得我不曾忘却的竹篱花香。
          现在想来，生活就如同养花。只要用一份努力、一份关爱、一种深情去培养、呵护，美好就会开放在我们目所能及的任何角落里。`,
          author: 'jilon',
          createTime: '2020-04-11',
          imgPath: '4.jpg'
        },
        {
          title: '生活永远在爱情之上',
          context: '女孩跟男友相恋了两年，男友对她好，挣的钱任她花，她对男友也好，为他洗洗涮涮，缝缝补补，两人的小日子过得有滋有味。可天有不测，男友突然遭遇车祸，受了重伤，完全丧失了劳动能力。只有二十四岁，余生都需要人照顾了。',
          author: 'jilon',
          createTime: '2020-04-11',
          imgPath: '5.jpg'
        },
        {
          title: '生活永远在爱情之上',
          context: `小时候，我家住在小城西南的一片平房里。那时的生活、物质条件远不如今，但爱美的人家都会在自家窗台下围起一道道的篱笆，并且撒下一些比较容易成活的花种。春天到来时，阳光斜照在窗前的园子里，光灿灿的黄撒得满地、满屋都是，如同秋收后颗粒饱满的橙黄色稻穗，
          让人看了忍不住把笑容挂在眼角、挂在眉梢。花开后，引来无数蝶舞蜂飞，任淡淡花香轻飘在如水似梦的季节里。种花草, 养生活竹篱中，修长的美人蕉花蔓低垂，似羞，又似在喃喃细语。一大片深绿、一大片浅绿的轻纱罗裙，层层叠叠褶皱着包裹了它娇美、玲珑的身体，以至于漂亮的瓢虫姑娘不小心跌落在它的裙褶中，
          只好在翠意浓浓的茎脉上奋力地爬行。看到这小小的瓢虫，
          谁都不会怀疑它是把春天送到我们面前的信使。瓢虫挣脱了绿叶的拥抱，展开薄的、透明的翅膀，跟随着漫天飞舞的蒲公英的小花伞，一上一下嬉闹着远去了。
          园子里能跟美人蕉争艳的当数大丽花了。大丽花的花瓣像堆积在一起的毛茸茸的猫耳朵，竖立在媚态十足的阳光里，似乎是在探听莺喃雁语里的爱隋。深红的、粉白的、大红的，每一朵笑脸上都看到了春姑娘的身影。
          站在竹篱外，闪亮的眸子紧盯着盛开的满园花草，生怕一眨眼的工夫它们就会消失得无踪无迹。如果真是那样，我还能到哪里再寻得这满眼的芬芳、满心的春情呢？
          大丽花的花根处，家人还种了些没有见过花开的薄荷。也许只有在夜深人静的时候，薄荷花才会羞怯怯地开放在无人的月夜里。揉碎后的薄荷叶子上散发出阵阵薄荷糖样的、清凉的香味。有时，我也会采下一些薄荷叶让父亲拿去用鸡蛋炒炒，那种感觉就像在吃梦寐已久的大餐。当飘着清香的薄荷叶炒蛋放在我的小碗里时，爸爸就会说：“天热了，吃点薄荷能解热去毒。”
          我不善养花草，虽说喜爱之情并不亚于他人，可总怕在我的伺弄下花草会渐渐枯萎，变成没有生命、没有水份的“干尸”。于是，不管遇见多艳丽、多优雅、多清香的花草，我也只是驻足下来一盆盆地欣赏。这时，卖花人会极力推荐，他的花木不仅品种好，而且成活率高。我用心地听着，然后老实作答：“看看就行，买回去怕养不活呢！”
          前几日，先生从国外回来，说想要让自家的小院变成长着绿草坡的花园。
          我笑笑说，只要你有时间，就来开辟吧！
          但先生太忙了，他每次也只是说说而已。不知道家中这小小的庭院里，何时才能芳香盈盈呢！
          数日前，院外又一次遇到那个卖花人。迎上去仔细打量着花的品种，花农说：“来一盆吧！多美丽的花，有了它们，你会天天陶醉在春天里。”
          哦，我想花农说得有道理。有了好的心情，有了一份花开的希望，我们才能生活得自如，不是吗？正如小时候，窗下竹篱里曾经承载着多少对美好生活的渴望，这种渴望在园子里的花朵上，更在爱花人的心里。于是，这次我买下了两盆比较容易管理的马蹄莲和茉莉花，同时也把一份殷殷的祝愿搬回了家。
          花盆搁置在台阶上：我们相互对望着，我赏识它的自然、青翠，还有昂扬的生命力。把儿子叫过来，俯下身一起闻着茉莉浓浓的花香，好让他在以后的岁月里也能记得，记得我不曾忘却的竹篱花香。
          现在想来，生活就如同养花。只要用一份努力、一份关爱、一种深情去培养、呵护，美好就会开放在我们目所能及的任何角落里。`,
          author: 'jilon',
          createTime: '2020-04-11',
          imgPath: '6.jpg'
        },
        {
          title: '生活永远在爱情之上',
          context: '女孩跟男友相恋了两年，男友对她好，挣的钱任她花，她对男友也好，为他洗洗涮涮，缝缝补补，两人的小日子过得有滋有味。可天有不测，男友突然遭遇车祸，受了重伤，完全丧失了劳动能力。只有二十四岁，余生都需要人照顾了。',
          author: 'jilon',
          createTime: '2020-04-11',
          imgPath: '7.jpg'
        },
        {
          title: '生活永远在爱情之上',
          context: `小时候，我家住在小城西南的一片平房里。那时的生活、物质条件远不如今，但爱美的人家都会在自家窗台下围起一道道的篱笆，并且撒下一些比较容易成活的花种。春天到来时，阳光斜照在窗前的园子里，光灿灿的黄撒得满地、满屋都是，如同秋收后颗粒饱满的橙黄色稻穗，
          让人看了忍不住把笑容挂在眼角、挂在眉梢。花开后，引来无数蝶舞蜂飞，任淡淡花香轻飘在如水似梦的季节里。种花草, 养生活竹篱中，修长的美人蕉花蔓低垂，似羞，又似在喃喃细语。一大片深绿、一大片浅绿的轻纱罗裙，层层叠叠褶皱着包裹了它娇美、玲珑的身体，以至于漂亮的瓢虫姑娘不小心跌落在它的裙褶中，
          只好在翠意浓浓的茎脉上奋力地爬行。看到这小小的瓢虫，
          谁都不会怀疑它是把春天送到我们面前的信使。瓢虫挣脱了绿叶的拥抱，展开薄的、透明的翅膀，跟随着漫天飞舞的蒲公英的小花伞，一上一下嬉闹着远去了。
          园子里能跟美人蕉争艳的当数大丽花了。大丽花的花瓣像堆积在一起的毛茸茸的猫耳朵，竖立在媚态十足的阳光里，似乎是在探听莺喃雁语里的爱隋。深红的、粉白的、大红的，每一朵笑脸上都看到了春姑娘的身影。
          站在竹篱外，闪亮的眸子紧盯着盛开的满园花草，生怕一眨眼的工夫它们就会消失得无踪无迹。如果真是那样，我还能到哪里再寻得这满眼的芬芳、满心的春情呢？
          大丽花的花根处，家人还种了些没有见过花开的薄荷。也许只有在夜深人静的时候，薄荷花才会羞怯怯地开放在无人的月夜里。揉碎后的薄荷叶子上散发出阵阵薄荷糖样的、清凉的香味。有时，我也会采下一些薄荷叶让父亲拿去用鸡蛋炒炒，那种感觉就像在吃梦寐已久的大餐。当飘着清香的薄荷叶炒蛋放在我的小碗里时，爸爸就会说：“天热了，吃点薄荷能解热去毒。”
          我不善养花草，虽说喜爱之情并不亚于他人，可总怕在我的伺弄下花草会渐渐枯萎，变成没有生命、没有水份的“干尸”。于是，不管遇见多艳丽、多优雅、多清香的花草，我也只是驻足下来一盆盆地欣赏。这时，卖花人会极力推荐，他的花木不仅品种好，而且成活率高。我用心地听着，然后老实作答：“看看就行，买回去怕养不活呢！”
          前几日，先生从国外回来，说想要让自家的小院变成长着绿草坡的花园。
          我笑笑说，只要你有时间，就来开辟吧！
          但先生太忙了，他每次也只是说说而已。不知道家中这小小的庭院里，何时才能芳香盈盈呢！
          数日前，院外又一次遇到那个卖花人。迎上去仔细打量着花的品种，花农说：“来一盆吧！多美丽的花，有了它们，你会天天陶醉在春天里。”
          哦，我想花农说得有道理。有了好的心情，有了一份花开的希望，我们才能生活得自如，不是吗？正如小时候，窗下竹篱里曾经承载着多少对美好生活的渴望，这种渴望在园子里的花朵上，更在爱花人的心里。于是，这次我买下了两盆比较容易管理的马蹄莲和茉莉花，同时也把一份殷殷的祝愿搬回了家。
          花盆搁置在台阶上：我们相互对望着，我赏识它的自然、青翠，还有昂扬的生命力。把儿子叫过来，俯下身一起闻着茉莉浓浓的花香，好让他在以后的岁月里也能记得，记得我不曾忘却的竹篱花香。
          现在想来，生活就如同养花。只要用一份努力、一份关爱、一种深情去培养、呵护，美好就会开放在我们目所能及的任何角落里。`,
          author: 'jilon',
          createTime: '2020-04-11',
          imgPath: '8.jpg'
        },
        {
          title: '生活永远在爱情之上',
          context: '女孩跟男友相恋了两年，男友对她好，挣的钱任她花，她对男友也好，为他洗洗涮涮，缝缝补补，两人的小日子过得有滋有味。可天有不测，男友突然遭遇车祸，受了重伤，完全丧失了劳动能力。只有二十四岁，余生都需要人照顾了。',
          author: 'jilon',
          createTime: '2020-04-11',
          imgPath: '9.jpg'
        },
        {
          title: '生活永远在爱情之上',
          context: `小时候，我家住在小城西南的一片平房里。那时的生活、物质条件远不如今，但爱美的人家都会在自家窗台下围起一道道的篱笆，并且撒下一些比较容易成活的花种。春天到来时，阳光斜照在窗前的园子里，光灿灿的黄撒得满地、满屋都是，如同秋收后颗粒饱满的橙黄色稻穗，
          让人看了忍不住把笑容挂在眼角、挂在眉梢。花开后，引来无数蝶舞蜂飞，任淡淡花香轻飘在如水似梦的季节里。种花草, 养生活竹篱中，修长的美人蕉花蔓低垂，似羞，又似在喃喃细语。一大片深绿、一大片浅绿的轻纱罗裙，层层叠叠褶皱着包裹了它娇美、玲珑的身体，以至于漂亮的瓢虫姑娘不小心跌落在它的裙褶中，
          只好在翠意浓浓的茎脉上奋力地爬行。看到这小小的瓢虫，
          谁都不会怀疑它是把春天送到我们面前的信使。瓢虫挣脱了绿叶的拥抱，展开薄的、透明的翅膀，跟随着漫天飞舞的蒲公英的小花伞，一上一下嬉闹着远去了。
          园子里能跟美人蕉争艳的当数大丽花了。大丽花的花瓣像堆积在一起的毛茸茸的猫耳朵，竖立在媚态十足的阳光里，似乎是在探听莺喃雁语里的爱隋。深红的、粉白的、大红的，每一朵笑脸上都看到了春姑娘的身影。
          站在竹篱外，闪亮的眸子紧盯着盛开的满园花草，生怕一眨眼的工夫它们就会消失得无踪无迹。如果真是那样，我还能到哪里再寻得这满眼的芬芳、满心的春情呢？
          大丽花的花根处，家人还种了些没有见过花开的薄荷。也许只有在夜深人静的时候，薄荷花才会羞怯怯地开放在无人的月夜里。揉碎后的薄荷叶子上散发出阵阵薄荷糖样的、清凉的香味。有时，我也会采下一些薄荷叶让父亲拿去用鸡蛋炒炒，那种感觉就像在吃梦寐已久的大餐。当飘着清香的薄荷叶炒蛋放在我的小碗里时，爸爸就会说：“天热了，吃点薄荷能解热去毒。”
          我不善养花草，虽说喜爱之情并不亚于他人，可总怕在我的伺弄下花草会渐渐枯萎，变成没有生命、没有水份的“干尸”。于是，不管遇见多艳丽、多优雅、多清香的花草，我也只是驻足下来一盆盆地欣赏。这时，卖花人会极力推荐，他的花木不仅品种好，而且成活率高。我用心地听着，然后老实作答：“看看就行，买回去怕养不活呢！”
          前几日，先生从国外回来，说想要让自家的小院变成长着绿草坡的花园。
          我笑笑说，只要你有时间，就来开辟吧！
          但先生太忙了，他每次也只是说说而已。不知道家中这小小的庭院里，何时才能芳香盈盈呢！
          数日前，院外又一次遇到那个卖花人。迎上去仔细打量着花的品种，花农说：“来一盆吧！多美丽的花，有了它们，你会天天陶醉在春天里。”
          哦，我想花农说得有道理。有了好的心情，有了一份花开的希望，我们才能生活得自如，不是吗？正如小时候，窗下竹篱里曾经承载着多少对美好生活的渴望，这种渴望在园子里的花朵上，更在爱花人的心里。于是，这次我买下了两盆比较容易管理的马蹄莲和茉莉花，同时也把一份殷殷的祝愿搬回了家。
          花盆搁置在台阶上：我们相互对望着，我赏识它的自然、青翠，还有昂扬的生命力。把儿子叫过来，俯下身一起闻着茉莉浓浓的花香，好让他在以后的岁月里也能记得，记得我不曾忘却的竹篱花香。
          现在想来，生活就如同养花。只要用一份努力、一份关爱、一种深情去培养、呵护，美好就会开放在我们目所能及的任何角落里。`,
          author: 'jilon',
          createTime: '2020-04-11',
          imgPath: '10.jpg'
        }
      ]
    }
  }
  render(): JSX.Element {
    return (
      <section>
        <List articleList={this.state.articleList}/>
      </section>
    )
  }
}

export default Lifestroy;