import React from 'react';
import './Img.less';

interface Iprops {
  imgurl: string;
}
class Img extends React.Component<Iprops> {

  render(): JSX.Element {
    let imgurl = `../../assets/img/${this.props.imgurl}`;
    return (
      <section className="img">
        <section className="img-item" style={{ background: `url(${imgurl}) no-repeat center center / cover`}}></section>
      </section>
    )
  }
}

export default Img;