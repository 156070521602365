import React from 'react';

class Message extends React.Component {
  render(): JSX.Element {
    return (
      <section className="slogan">
        <span>正在建设中，马上开放...</span>
      </section>
    )
  }
}

export default Message;