import React from 'react';
import { connect } from 'react-redux';
import { IpropAny } from '@/interface/propsInterface';
import Img from '@component/Img/Img';
import './Content.less';

class Content extends React.Component<IpropAny>{
  
  render(): JSX.Element {
    return (
      <section className="content">
        <h3>{this.props.article.title}</h3>
        <section className="content-info">
          <span>作者：{this.props.article.author}</span>
          <span>发表时间：{this.props.article.createTime}</span>
        </section>
        <section className="content-img">
          <Img imgurl={this.props.article.imgPath} />
        </section>
        <section className="context">{this.props.article.context}</section>
      </section>
    )
  }
}

const mapStateToProps = (state: any) => ({
  article: state.updateArticleContent.articleItem
})

export default connect(mapStateToProps)(Content);