import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Swiper from '@component/Swiper/Swiper';
import { IpropAny } from '@/interface/propsInterface';
import { IstateAny } from '@/interface/stateInterface';
import Nav from '@component/Nav/Nav';
import Lifestory from '@view/Lifestory/Lifestory';
import Travel from '@view/Travel/Travel';
import Content from '@view/Content/Content';
import './Article.less';


class Article extends React.Component<IpropAny, IstateAny> {
  constructor(props: any) {
    super(props);
    this.state = {
      autoplaySpeed: 8000,
      speed: 800,
      navlist: [
        { name: '生活故事', path: 'article/lifestory', component: Lifestory },
        { name: '旅游杂谈', path: 'article/travel', component: Travel },
        { name: '体育分享', path: 'article/sport', component: Travel },
        { name: '精彩图片', path: 'article/photograph', component: Lifestory }
      ]
    }
  }
  render(): JSX.Element {
    return (
      <section className="article-box">
        <section>
          <Swiper autoplaySpeed={this.state.autoplaySpeed} speed={this.state.speed}/>
        </section>
        <section className="article-container">
          <Nav navlist={this.state.navlist}/>
          <section >
            {this.state.navlist.map((item: IpropAny) => {
              return (
                <Route exact strict path={`/${item.path}`} component={item.component} key={item.path} />
              )
            })}
            <Route exact path="/article/content" component={Content} />
            <Redirect exact strict path='/article' to='/article/lifestory' />
          </section>
        </section>
      </section>
    )
  }
}

export default Article;