import React from 'react';
import './Slogan.less';

class Slogan extends React.Component {
  render(): JSX.Element {
    return (
      <section className="slogan">
        <span>欢迎访问畅恪</span>
        <span>~</span>
      </section>
    )
  }
}

export default Slogan;
