import React from 'react';
import 'antd/dist/antd.css';
import './App.less';
import Head from '@component/Head/Head';
import Foot from '@component/Foot/Foot';

class App extends React.Component {
  
  render() {
    return (
      <div className="App">
        <Head />
        <section>
          {this.props.children}
        </section>
        <Foot />
      </div>
    );
  } 
}

export default App;
