import React from 'react';
import './Logo.less';

class Logo extends React.Component {
  render(): JSX.Element {
    return (
      <section className="logo">LONGGUEST</section>
    )
  }
}

export default Logo;