import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import App from '@view/App/App';
import Article from '@view/Article/Article';
import Message from '@view/Message/Message';
import Album from '@view/Album/Album';
import About from '@view/About/About';


interface RouterConfig {
  path: string,
  component?: any
}

class AppRouter extends React.PureComponent{
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route path="/" render={() => 
            <App>
              <Route exact path="/" component={Article} />
              <Route path="/article" component={Article} />
              <Route exact path="/message" component={Message} />
              <Route exact path="/album" component={Album} />
              <Route exact path="/about" component={About} />
            </App>
          }>
          </Route>
        </Switch>
      </HashRouter>
    )
  }

}

export default AppRouter;