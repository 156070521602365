import React from 'react';
import { IpropAny } from '@/interface/propsInterface';
import { IstateAny } from '@/interface/stateInterface';
import './Foot.less';

class Foot extends React.Component<IpropAny, IstateAny> {
	constructor(props: IpropAny) {
		super(props);
		this.state = {
			link: [
				{ 
					name: '生活',
					type: 'life',
					list: [
						{
							name: '今日头条',
							url: 'https://www.toutiao.com/'
						},
						{
							name: '网易',
							url: 'https://www.163.com/'
            },
            {
              name: '微博',
              url: 'https://weibo.com/'
            },
            {
              name: '墨迹天气',
              url: 'http://www.moji.com/'
            }
					]
				},
				{ 
					name: '技术',
					type: 'technology',
					list: [
						{
							name: '阿里云',
							url: 'https://www.aliyun.com/'
            },
            {
              name: 'github',
              url: 'https://www.github.com/'
            },
            {
              name: '码云',
              url: 'https://www.gitee.com/'
            },
            {
              name: 'StackOverFlow',
              url: 'http://stackoverflow.com/'
            }
					]
				},
				{ 
					name: '体育',
					type: 'sport',
					list: [
						{
							name: '直播吧',
							url: 'https://www.zhibo8.cc/'
            },
            {
              name: '中国体育',
              url: 'https://www.cntv.cn/'
            }
					]
				},
				{ 
					name: '休闲',
					type: 'video',
					list: [
						{
							name: '优酷网',
							url: 'https://www.youku.com/'
            },
            {
              name: '哔哩哔哩',
              url: 'https://www.bilibili.com/'
            },
            {
              name: '在线游戏',
              url: 'https://www.yikm.net/'
            }
					]
        },
        {
          name: '建议服务',
          type: 'advs',
          list: [
            {
              name: '在线反馈',
              path: 'online-backend'
            },
            {
              name: '内容发布',
              path: 'adv-service'
            },
            {
              name: '工作室说明',
              path: 'room-info'
            },
            {
              name: '投诉建议',
              path: 'complaint'
            }
          ]
        }
			]
		}
	}
	render() {
		return (
			<footer>
				<section className="link">
					{/* 友情链接部分 */}
					{/*<ul className="link-container">
						{this.state.link.map((link: IstateAny) => {
							return (
								<section key={link.type} className="link-item-container">
									<h3>{link.name}</h3>
									<ul>
										{link.list.map((list: IstateAny) => {
											return (
                        <li key={list.url || list.path} className="link-item">
                          {list.url ? 
                            <a href={list.url} target="_blank" rel="noopener noreferrer">{list.name}</a> :
                            <span>{list.name}</span>
                          }
											  </li>
											)
										})}
									</ul>
								</section>
							)
						})}
          </ul>*/}
          {/* qrcode */}
					{/*<section className="qrcode-container"></section>*/}
					{/* 联系信息 */}
          {/*<section className="contact">
            <h3>联系方式</h3>
            <ul>
              <li>
                <span>微信： </span>
                <span>summer_flash</span>
              </li>
              <li>
                <span>邮箱： </span>
                <span>longguest_admin@163.com</span>
              </li>
            </ul>
					</section>*/}
				</section>
				{/* 版权信息 */}
				<section className="copyright">
          <p>
            <span>畅恪&nbsp;&nbsp;版权所有&nbsp;&copy;&nbsp;2019-2020 </span>
            <a href="http://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">陕ICP备20003099号-1</a>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;～生命有限，只能接受～</span>
          </p>
				</section>
			</footer>
    )
	}
}

export default Foot;
