import React from 'react';

class Travel extends React.Component {
  render(): JSX.Element {
    return (
      <section >
        <span>正在建设中，马上开放...</span>
      </section>
    )
  }
}

export default Travel;